import { memo, useMemo } from 'react';
import styled from '@emotion/styled';

import AvatarGroup from 'components/assignees/AvatarGroup';
import Text from 'components/text';
import Tooltip from 'components/tooltip/Tooltip';
import { useAuthContext } from 'contexts/AuthContext';
import useUserEnrichedItems from 'hooks/useEnrichedUsers';
import useSharedResource, {
  getSessionUser,
  getUserIdFromParticipant,
} from 'hooks/useSharedResource';
import { SessionUser } from 'types/sessionUser';
import { getUserColor } from 'utils/userColor';

export interface ActiveResourceUserProps {
  resourceId: string;
  maxAvatarToShow?: number;
}

function getUserBorderColor(user: SessionUser) {
  return user.status === 'visible' ? getUserColor(user) : undefined;
}

function getUserKey(user: SessionUser) {
  return `${user.mId}@${user.sessionId}`;
}

const TooltipText = styled(Text)`
  white-space: pre-line;
`;

function ActiveResourceUsers({
  resourceId,
  maxAvatarToShow = 5,
}: Readonly<ActiveResourceUserProps>) {
  const context = useAuthContext();
  const { configured, others /*, updateState*/ } = useSharedResource(
    `default/resource/${resourceId.replace(/_/g, '-')}`,
    context.user?.dinaUserId ?? '',
    '',
  );
  const otherUsers = useUserEnrichedItems(others, getUserIdFromParticipant, getSessionUser);
  const overflowUsers = Math.max(0, otherUsers.length - maxAvatarToShow);
  const users = overflowUsers > 0 ? otherUsers.slice(0, maxAvatarToShow) : otherUsers;

  const titleOverflow = Math.max(0, otherUsers.length - 10);
  const visibleTitles = titleOverflow > 0 ? otherUsers.slice(0, 10) : otherUsers;

  const tooltipTitle = useMemo(() => {
    const titles = visibleTitles.map((member) => member.mTitle).join('\n');
    return titleOverflow > 0 ? `${titles}\n...and ${titleOverflow} more` : titles;
  }, [visibleTitles, titleOverflow]);

  if (!configured) return <span />;
  return (
    <Tooltip
      title={
        <TooltipText variant="caption" color="whiteHighEmphasis">
          <Text variant="caption" color="mediumEmphasis">
            Active Users{'\n'}
          </Text>
          {tooltipTitle}
        </TooltipText>
      }
    >
      <div>
        <AvatarGroup
          users={users}
          getUserBorderColor={getUserBorderColor}
          getUserKey={getUserKey}
          borderWidth={2}
          overflow={overflowUsers}
        />
      </div>
    </Tooltip>
  );
}

export default memo(ActiveResourceUsers);
