import { useQuery } from '@apollo/client';

import GET_RUNDOWN from 'operations/queries/getRundown';
import { QueryGetRundownArgs, Rundown } from 'types/graphqlTypes';

export const rundownTypes = {
  RUNDOWN: 'rundown',
  RUNDOWN_TEMPLATE: 'rundowntemplate',
} as const;

export type RundownMemberType = (typeof rundownTypes)[keyof typeof rundownTypes];

export interface AdjustedRundownType extends Omit<Rundown, 'mType'> {
  mId: string;
  mType: RundownMemberType;
  message?: string;
}
export type GetRundownReturnType = {
  getRundown: AdjustedRundownType;
};

type GetRundownInput = {
  mId: string;
};

const useGetRundown = ({ mId }: GetRundownInput) => {
  const { data, error, loading, refetch } = useQuery<GetRundownReturnType, QueryGetRundownArgs>(
    GET_RUNDOWN,
    {
      variables: {
        input: {
          mId: mId,
          mRefId: mId,
        },
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
    },
  );

  return { data, error, loading, refetch };
};

export default useGetRundown;
