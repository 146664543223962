/* eslint-disable no-console */
/**
 * This hook uses appsync events to keep track of users who are looking at a shared resource.
 * It starts by connecting to a channel for the resource and for to a channel for unicast events
 * to myself and to send a `connect` event to the resource's channel
 * The corresponding hooks in other sessions will respond by repeatedly uni-casting a
 * `connectionConfirmed` event back to this hook and will do so until they have received a
 * uni-casted `connected` event.
 * In addition this hook will send some initial `heartBeat` events to ensure that hooks that
 * connected to the resource's channel simultaneously are aware of me.
 * If a hook receives a heartbeat without first receiving a connect event will send a uni-casted
 * `requestConnection` event back and wait for a `acceptConnection` event.
 * When the hook is unmounted (or window is hidden), it will send a `disconnect` event to the
 * resource's channels and will stop listening to events from the resource's channels.
 * @module
 */

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DocumentType } from '@apollo/client';
import { events, EventsChannel } from 'aws-amplify/data';

import { getDummyUser } from 'store/members';
import { User } from 'types';
import { CustomChannel } from 'types/customChannel';
import { SessionUser } from 'types/sessionUser';
import { DINA_SESSION_ID } from 'utils/sessionId';

const NONE = Object.freeze([]);

export interface Participant<T> {
  readonly sessionId: string;
  readonly userId: string;
  /** The time when the participant was last visible or NaN if visible */
  readonly hiddenTime: number;
  readonly state: T;
}

export function getUserIdFromParticipant(participant: Participant<unknown>): string {
  return participant.userId;
}

export function getSessionUser(
  participant: Participant<unknown>,
  user: User | undefined,
): SessionUser {
  return Object.freeze({
    ...(user ?? getDummyUser(participant.userId)),
    sessionId: participant.sessionId,
    status: isNaN(participant.hiddenTime) ? 'visible' : 'hidden',
  });
}

interface ConnectEvent<T> {
  readonly type: 'connect' | 'connectionConfirmed' | 'requestConnection' | 'acceptConnection';
  readonly sourceId: string;
  readonly userId: string;
  readonly state: T;
}

interface UpdateEvent<T> {
  readonly type: 'update';
  readonly sourceId: string;
  readonly state: T;
}

interface SimpleEvent {
  readonly type: 'disconnect' | 'connected' | 'heartBeat';
  readonly sourceId: string;
}

interface CustomEvent {
  readonly type: 'custom';
  readonly sourceId: string;
  readonly customType: string;
  readonly customData?: unknown;
}

type TestEvent<T> = ConnectEvent<T> | UpdateEvent<T> | SimpleEvent | CustomEvent;

function broadcastEvent<T>(channelNamespace: string, event: TestEvent<T>) {
  events
    .post(channelNamespace, event as unknown as DocumentType)
    .catch((error) => console.error('failed publishing', error));
}

function unicastEvent<T>(channelNamespace: string, event: TestEvent<T>, receiverId: string) {
  broadcastEvent(`${channelNamespace}/${receiverId}`, event);
}

function isTestEventData<T>(x: unknown): x is { event: TestEvent<T> } {
  return (
    !!x &&
    typeof x === 'object' &&
    'event' in x &&
    !!x.event &&
    typeof x.event === 'object' &&
    'type' in x.event &&
    typeof x.event.type === 'string'
  );
}

export type CustomEventHandler = (sourceSessionId: string, type: string, data?: unknown) => void;
type CustomEventHandlerMap = Record<string, CustomEventHandler[]>;

function addCustomEventHandler(
  map: CustomEventHandlerMap,
  type: string,
  handler: CustomEventHandler,
) {
  if (!(type in map)) map[type] = [handler];
  else map[type].push(handler);
}

function removeCustomEventHandler(
  map: CustomEventHandlerMap,
  type: string,
  handler: CustomEventHandler,
) {
  if (!(type in map)) return;
  const handlers = map[type];
  const pos = handlers.indexOf(handler);
  if (pos < 0) return;
  handlers.splice(pos, 1);
  if (handlers.length === 0) delete map[type];
}

function handleCustomEvent(
  map: CustomEventHandlerMap,
  sourceSessionId: string,
  type: string,
  data?: unknown,
) {
  if (!(type in map)) return;
  map[type].forEach((handler) => handler(sourceSessionId, type, data));
}

/**
 * Removes participants that were hidden more that a minute ago
 * @param participants The original participants
 * @returns            An array containing the participants that have not been hidden for more than
 *                     a minute. If all original participants are included, the SAME array will be
 *                     returned.
 */
function removeInactive<T>(participants: readonly Participant<T>[]) {
  const now = Date.now();
  const updated = participants.filter((o) => isNaN(o.hiddenTime) || o.hiddenTime > now - 60000);
  return updated.length < participants.length ? Object.freeze(updated) : participants;
}

export interface SharedResourceUsage<T> {
  /** The other users that looks at the same resource */
  others: readonly Participant<T>[];
  /** Update the state of the resource as seen by the caller */
  updateState: (state: T, debounceTime?: number) => void;
  /** `true` if the event API for the communication has configured, otherwise `false` */
  configured: boolean;
  /** A custom channel that can be used to send/receive custom events */
  customChannel: CustomChannel;
}

/**
 * Uses a shared resource identified by its channel
 * @param channelNamespace The namespace for the resource
 * @param userId           The ID of the current user
 * @param initialState     The initial state for the resource (use the returned `updateState` to
 *                         notify about changes)
 * @returns                The {@link SharedResourceUsage}
 */
export default function useSharedResource<T>(
  channelNamespace: string,
  userId: string,
  initialState: T,
): SharedResourceUsage<T> {
  const customEventHandlers = useRef<CustomEventHandlerMap>({});
  const stateRef = useRef(initialState);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const configured = !!import.meta.env.REACT_APP_API_EVENTS_ENDPOINT;
  const mySessionId = DINA_SESSION_ID;
  const [others, setOthers] = useState<readonly Participant<T>[]>(NONE);

  const updateState = useCallback((state: T, debounceTime?: number) => {
    stateRef.current = state;
    if (timeoutRef.current !== undefined) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
    if (debounceTime !== undefined) {
      timeoutRef.current = setTimeout(
        () => broadcastEvent(channelNamespace, { type: 'update', state, sourceId: mySessionId }),
        debounceTime,
      );
    } else {
      broadcastEvent(channelNamespace, { type: 'update', state, sourceId: mySessionId });
    }
  }, []);

  const updateOthers = useCallback(
    (sourceSessionId: string, state: T, sourceUserId?: string) => {
      setOthers((prevOthers) => {
        const prevPos = prevOthers.findIndex((p) => p.sessionId === sourceSessionId);
        const prev = prevPos >= 0 ? prevOthers[prevPos] : undefined;
        const updatedUserId = sourceUserId ?? prev?.userId ?? '<unknown>';
        const updated = Object.freeze({
          sessionId: sourceSessionId,
          userId: updatedUserId,
          state,
          hiddenTime: NaN,
        });
        return Object.freeze(
          prevPos >= 0 ? prevOthers.toSpliced(prevPos, 1, updated) : [...prevOthers, updated],
        );
      });
    },
    [setOthers],
  );

  const broadCastCustomEvent = useCallback(
    (type: string, data: unknown) => {
      broadcastEvent(channelNamespace, {
        type: 'custom',
        sourceId: mySessionId,
        customType: type,
        customData: data,
      });
    },
    [channelNamespace, mySessionId],
  );

  const addEventListener = useCallback(
    (type: string, handler: CustomEventHandler) => {
      addCustomEventHandler(customEventHandlers.current, type, handler);
    },
    [customEventHandlers],
  );

  const removeEventListener = useCallback(
    (type: string, handler: CustomEventHandler) => {
      removeCustomEventHandler(customEventHandlers.current, type, handler);
    },
    [customEventHandlers],
  );

  const hideOther = useCallback(
    (sessionId: string) => {
      setOthers((prevOthers) => {
        const pos = prevOthers.findIndex((o) => o.sessionId === sessionId);
        if (pos < 0) return prevOthers;
        const updated = Object.freeze({ ...prevOthers[pos], hiddenTime: Date.now() });
        return Object.freeze(prevOthers.toSpliced(pos, 1, updated));
      });
    },
    [setOthers],
  );

  const customChannel: CustomChannel = useMemo(
    () => ({
      addEventListener,
      removeEventListener,
      broadcastEvent: broadCastCustomEvent,
    }),
    [addEventListener, removeEventListener, broadCastCustomEvent],
  );

  useEffect(() => {
    if (configured) {
      let channel: EventsChannel | undefined;
      let myChannel: EventsChannel | undefined;
      const pendingConnections: Record<string, ReturnType<typeof setInterval>> = {};
      const connections = new Set<string>();
      let initialHeartbeatInterval: ReturnType<typeof setInterval> | undefined = undefined;
      let initialHeartbeatCount = 0;

      const ensureConnectionConfirmed = (sessionId: string) => {
        pendingConnections[sessionId] = setInterval(() => {
          unicastEvent(
            channelNamespace,
            {
              type: 'connectionConfirmed',
              sourceId: mySessionId,
              userId: userId,
              state: stateRef.current,
            },
            sessionId,
          );
        }, 500);
      };

      const connectAndSubscribe = async () => {
        channel = await events.connect(channelNamespace);
        myChannel = await events.connect(`${channelNamespace}/${mySessionId}`);

        channel.subscribe({
          next: (data) => {
            if (!isTestEventData<T>(data) || data.event.sourceId === mySessionId) return;
            switch (data.event.type) {
              case 'disconnect':
                hideOther(data.event.sourceId);
                break;
              case 'update':
                updateOthers(data.event.sourceId, data.event.state);
                break;
              case 'connect':
                updateOthers(data.event.sourceId, data.event.state, data.event.userId);
                connections.add(data.event.sourceId);
                ensureConnectionConfirmed(data.event.sourceId);
                break;
              case 'heartBeat':
                if (
                  !(data.event.sourceId in pendingConnections) &&
                  !connections.has(data.event.sourceId)
                ) {
                  unicastEvent(
                    channelNamespace,
                    {
                      type: 'requestConnection',
                      sourceId: mySessionId,
                      userId: userId,
                      state: stateRef.current,
                    },
                    data.event.sourceId,
                  );
                }
                break;
              case 'custom':
                handleCustomEvent(
                  customEventHandlers.current,
                  data.event.sourceId,
                  data.event.customType,
                  data.event.customData,
                );
                break;
            }
          },
          error: (err) => console.error('broadcast event receive error:', err),
        });

        myChannel.subscribe({
          next: (data) => {
            if (!isTestEventData<T>(data) || data.event.sourceId === mySessionId) return;
            switch (data.event.type) {
              case 'requestConnection':
                updateOthers(data.event.sourceId, data.event.state, data.event.userId);
                connections.add(data.event.sourceId);
                unicastEvent(
                  channelNamespace,
                  {
                    type: 'acceptConnection',
                    sourceId: mySessionId,
                    userId: userId,
                    state: stateRef.current,
                  },
                  data.event.sourceId,
                );
                break;
              case 'acceptConnection':
                updateOthers(data.event.sourceId, data.event.state, data.event.userId);
                connections.add(data.event.sourceId);
                break;
              case 'connectionConfirmed':
                updateOthers(data.event.sourceId, data.event.state, data.event.userId);
                connections.add(data.event.sourceId);
                unicastEvent(
                  channelNamespace,
                  {
                    type: 'connected',
                    sourceId: mySessionId,
                  },
                  data.event.sourceId,
                );
                break;
              case 'connected':
                if (data.event.sourceId in pendingConnections) {
                  clearInterval(pendingConnections[data.event.sourceId]);
                  delete pendingConnections[data.event.sourceId];
                }
                break;
              case 'custom':
                handleCustomEvent(
                  customEventHandlers.current,
                  data.event.sourceId,
                  data.event.customType,
                  data.event.customData,
                );
                break;
            }
          },
          error: (err) => console.error('unicast event receive error:', err),
        });

        broadcastEvent(channelNamespace, {
          type: 'connect',
          sourceId: mySessionId,
          userId,
          state: stateRef.current,
        });

        // When two connects simultaneously, they may miss connect messages from each other.
        // We therefore sends some heart beats so that we can do request/accept connection instead
        initialHeartbeatInterval = setInterval(() => {
          broadcastEvent(channelNamespace, { type: 'heartBeat', sourceId: mySessionId });

          if (initialHeartbeatCount++ > 4) {
            clearInterval(initialHeartbeatInterval);
            initialHeartbeatInterval = undefined;
          }
        }, 300);
        initialHeartbeatCount = 0;
      };

      const disconnect = () => {
        broadcastEvent(channelNamespace, { type: 'disconnect', sourceId: mySessionId });
        channel?.close();
        myChannel?.close();
        channel = myChannel = undefined;
        setOthers(NONE);
        const ids: string[] = [];
        Object.entries(pendingConnections).forEach(([id, interval]) => {
          clearInterval(interval);
          ids.push(id);
        });
        ids.forEach((id) => delete pendingConnections[id]);
        connections.clear();
        if (initialHeartbeatInterval !== undefined) {
          clearInterval(initialHeartbeatInterval);
          initialHeartbeatInterval = undefined;
        }
      };

      if (document.visibilityState === 'visible') {
        void connectAndSubscribe();
      }

      const onVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
          disconnect();
        } else if (!channel) {
          void connectAndSubscribe();
        }
      };
      document.addEventListener('visibilitychange', onVisibilityChange);

      return () => {
        disconnect();
        document.removeEventListener('visibilitychange', onVisibilityChange);
        Object.keys(customEventHandlers.current).forEach(
          (type) => delete customEventHandlers.current[type],
        );
      };
    } else {
      return () => undefined;
    }
  }, [channelNamespace, mySessionId, handleCustomEvent, setOthers]);

  // The only purpose with this effect is to remove items that have been hidden for a while
  useEffect(() => {
    const interval = setInterval(() => {
      setOthers(removeInactive);
    }, 10000);
    return () => clearInterval(interval);
  }, [setOthers]);

  return { others, updateState, configured, customChannel };
}
