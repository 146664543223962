/* eslint-disable no-template-curly-in-string */
/* eslint-disable max-len */

const mainSettingsView = {
  id: 'main-settings',
  name: 'Settings',
  categories: [
    {
      title: 'Application',
      description: 'General application properties',
      visible: true,
      properties: {
        'app.title': {
          label: 'App title (optional) in top header',
          placeholder: 'Type the app title here...',
          type: 'string',
          default: '',
          description: null,
          visible: true,
        },
        'app.rightPanel.width': {
          label: 'Resources panel (right panel) default width in pixels',
          placeholder: 'Type width',
          type: 'number',
          default: 327,
          description: null,
          visible: true,
        },
        'app.timezone': {
          label: 'Timezone',
          placeholder: 'Select timezone',
          type: 'autocomplete',
          default: Intl.DateTimeFormat().resolvedOptions().timeZone,
          description: null,
          visible: true,
        },
        'app.defaultOneWayInstanceLinks': {
          label: 'Instance link type',
          type: 'boolean',
          default: false,
          description: 'Use one-way links as default for instance links',
          visible: true,
        },
        'app.textDirection': {
          label: 'Default direction for text input',
          type: 'select',
          default: 'auto',
          options: [
            {
              id: 'auto',
              title: 'Auto (determined by browser)',
              value: 'auto',
            },
            {
              id: 'ltr',
              title: 'Left to Right',
              value: 'ltr',
            },
            {
              id: 'rtl',
              title: 'Right to Left',
              value: 'rtl',
            },
          ],
          visible: true,
        },
        'app.autoArchiveStory': {
          label: 'Auto archive story',
          type: 'boolean',
          default: false,
          description: 'Auto-Archive Stories',
          visible: true,
          isBetaLabel: true,
          childKeys: ['app.autoArchiveInstance', 'app.autoArchiveAfterDays'],
        },
        'app.autoArchiveInstance': {
          label: 'Auto archive instances',
          type: 'boolean',
          default: false,
          description: 'Auto-Archive instances',
          visible: false,
          isBetaLabel: true,
          parentKey: 'app.autoArchiveStory',
        },
        'app.autoArchiveAfterDays': {
          label: 'Archive after number of days',
          type: 'select',
          default: '7',
          visible: false,
          isBetaLabel: true,
          parentKey: 'app.autoArchiveStory',
          options: [
            { id: '3 days', title: '3 days', value: '3' },
            { id: '4 days', title: '4 days', value: '4' },
            { id: '4 days', title: '5 days', value: '5' },
            { id: '6 days', title: '6 days', value: '6' },
            { id: '1 week', title: '1 week', value: '7', showTopDivider: true },
            { id: '2 week', title: '2 week', value: '14' },
            { id: '3 week', title: '3 week', value: '21' },
            { id: '4 week', title: '4 week', value: '28' },
          ],
        },
        'notification.teamDeptAssignToInstance': {
          label: 'Notifications',
          type: 'boolean',
          default: false,
          description: 'Receive notifications on Team or Department assignment',
          visible: true,
        },
        'app.convertPitchToStory': {
          label: 'Convert Pitch',
          type: 'boolean',
          default: false,
          description: 'Delete Pitch by converting it to a Story',
          visible: true,
        },
        'app.showOrderButtonInTasks': {
          label: 'Show Order button in Tasks',
          type: 'boolean',
          default: false,
          description: 'Show the order button in Tasks (sub tasks)',
          visible: true,
        },
        'app.showOrderButtonInPlanningItems': {
          label: 'Show Order button in Planning Items',
          type: 'boolean',
          default: false,
          description: 'Show the order button in the planning items',
          visible: true,
        },
        'app.instance.copyStoryAssignees': {
          label: 'Set assignees for instance from parent story',
          type: 'boolean',
          default: false,
          description: 'Copy assignees from parent story for new instance',
          visible: true,
        },
        'app.enabledPitchSync': {
          label: 'Enable pitch sync',
          type: 'boolean',
          default: false,
          description: 'Enable Pitch synchronization',
          visible: true,
        },
        'app.contactFieldsToPrint': {
          label: 'Fields to Print on Contact Information',
          placeholder: 'Enter contact field IDs or "all"...',
          type: 'string',
          default: 'all',
          description:
            'Comma-separated field IDs to print on contact details, or "all" for all fields.',
          visible: true,
        },
        'app.instance.youtube.predefinedTags': {
          label: 'Allow only system defined tags for youtube',
          placeholder: 'Allow system defined tags only',
          type: 'boolean',
          default: false,
          description: 'Only allow system defined youtube tags',
          visible: true,
        },
      },
    },
    {
      title: 'MAM',
      description: 'Media Asset Management',
      visible: true,
      properties: {
        'mam.placeholderName': {
          label: 'Default placeholder name for assets',
          placeholder: 'Type the default placeholder name here...',
          type: 'string',
          default: '${mTitle}',
          description: null,
          visible: true,
        },
        'mam.placeholderName.maxLength': {
          label: 'Max characters in placeholder name. Set 0 for no limit',
          placeholder: 'Type the default placeholder name here...',
          type: 'number',
          default: 0,
          description: null,
          visible: true,
        },
        'mam.placeholderName.maxLengthMessage': {
          label: 'Message when placeholder name exceeds max length',
          placeholder: 'Type max length here..',
          type: 'string',
          default: 'The placeholder name cannot exceed 31 characters',
          description: null,
          visible: true,
        },
        'mam.placeholderName.characters': {
          label: 'Valid characters for placeholder name (regexp). Ignored when empty',
          placeholder: 'Type valid characters here..',
          type: 'string',
          default: '',
          description: null,
          visible: true,
        },
        'mam.placeholderName.charactersMessage': {
          label: 'Message for invalid characters in placeholder',
          placeholder: 'Type message here..',
          type: 'string',
          default: 'The placeholder name contains invalid characters',
          visible: true,
        },
        'mam.placeholderName.conjunctiveCharacter': {
          label: 'Character to replace invalid characters in placeholder',
          placeholder: 'Type characters here..',
          type: 'string',
          default: '_',
          description: null,
          visible: true,
        },
        'mam.placeholderName.hasDuplicateMessage': {
          label: 'Message for duplicate placeholder names',
          type: 'string',
          default: 'The placeholder name already exists',
          visible: true,
        },
        'mam.placeholderName.defaultHint': {
          label: 'Placeholder default hint ',
          type: 'string',
          default: '',
          visible: true,
        },
      },
    },
    {
      title: 'Rundown',
      description: 'General rundown properties',
      visible: true,
      properties: {
        'rundown.defaultReadSpeed': {
          label: 'Default read speed (words per minute)',
          placeholder: 'Type read speed',
          type: 'number',
          default: '150',
          description: null,
          visible: true,
        },
        'rundown.confirmOnNotSaved': {
          label: 'Confirmation dialog',
          type: 'boolean',
          default: false,
          description: 'Show confirmation dialog when exiting the Instance editor',
          visible: true,
        },
        'rundown.instance.showAutomationIcons': {
          label: 'Show Mosart Automation icons',
          type: 'boolean',
          default: 'true',
          description: 'Show Mosart automation icons in the linear instance toolbar',
          visible: true,
        },
        'rundown.autoSync': {
          label: 'Auto synchronise',
          type: 'boolean',
          default: false,
          description: 'Auto synchronise Mimir folder',
          visible: true,
        },
        'rundown.enableStudios': {
          label: 'Multiple Studios',
          type: 'boolean',
          default: false,
          description: 'Enable multiple studios support',
          visible: true,
        },
        'rundown.audioEnabled': {
          label: 'Audio rundown',
          type: 'boolean',
          default: false,
          description: 'Enable audio rundown',
          visible: true,
        },
        'rundown.manualPlaybackEnabled': {
          label: 'Manual timing bar',
          type: 'boolean',
          default: false,
          description: 'Enable manual timing bar',
          visible: true,
        },
        'rundown.markairedinstances': {
          label: 'Mark aired instances',
          type: 'boolean',
          default: false,
          description: 'Mark aired instances',
          visible: true,
        },
        'rundown.updateCumetimeOnTimelineMove': {
          label: 'Sets cumetime to onair time when timeline moves',
          type: 'boolean',
          default: false,
          description: 'Sets cumetime to instance onair time (in-time) when timeline moves',
          visible: true,
          isBetaLabel: true,
        },
        'rundown.useDurationBasedTiming': {
          label: 'Using duration based timing for cume/back times',
          type: 'boolean',
          default: false,
          description: 'Using duration based cume/back times if no hard-hit times are entered',
          visible: true,
          isBetaLabel: true,
        },
        'rundown.instance.totalDurationField': {
          label: 'Selects how to calculate instance durations for cume/back times',
          description: 'Selects fields used for total duration calculations',
          type: 'select',
          isBetaLabel: true,
          default: '07-total-duration',
          options: [
            {
              id: 'S01-total',
              title: 'Total',
              value: '07-total-duration',
            },
            {
              id: 'S02-planned',
              title: 'Planned',
              value: '06-planned-duration',
            },
            {
              id: 'S03-total|planned',
              title: 'Total | Planned',
              value: '07-total-duration,06-planned-duration',
            },
            {
              id: 'S04-planned|total',
              title: 'Planned | Total',
              value: '06-planned-duration,07-total-duration',
            },
          ],
          visible: true,
        },
        'rundown.enableAutoClipDuration': {
          label: 'Auto Clip Duration',
          type: 'boolean',
          default: false,
          description: 'Enable auto clip duration calculation',
          visible: true,
        },
        'rundown.showInstanceUpdatedBy': {
          label: 'Show user who updated instance',
          type: 'boolean',
          default: false,
          description: 'Display instance updater',
          visible: true,
        },
        'rundown.video.postroll': {
          label: 'Video postroll',
          type: 'number',
          default: 0,
          description: 'Video postroll used in clip duration calculations',
          visible: true,
        },
        'rundown.showInstanceStatus': {
          label: 'Show instance status',
          type: 'boolean',
          default: false,
          visible: true,
          description: 'Display instance status',
        },
        'rundown.showInstanceClipStatus': {
          label: 'Show instance clip status',
          type: 'boolean',
          default: false,
          visible: true,
          description: 'Display clip status of instance',
        },
        'rundown.showInstanceAssignee': {
          label: 'Show instance assignee',
          type: 'boolean',
          default: true,
          visible: true,
          description: 'Display instance assignee',
        },
        'rundown.hideAutomationIcons': {
          label: 'Hide the automation icons',
          type: 'boolean',
          default: false,
          visible: true,
          description: 'Hide the automation icons in the rundown',
        },
        'rundown.includePrefix': {
          label: 'Include prefix when renumbering a rundown',
          type: 'boolean',
          default: false,
          visible: true,
          description: 'Include prefix when renumbering a rundown',
        },
        'rundown.configureAssigneeColumn': {
          label: 'How to display assigned users in the rundown',
          type: 'select',
          default: 'both',
          options: [
            {
              id: 'both',
              title: 'Avatar and initials',
              value: 'both',
            },
            {
              id: 'avatar',
              title: 'Avatar only',
              value: 'avatar',
            },
            {
              id: 'initials',
              title: 'Initials only',
              value: 'initials',
            },
          ],
          visible: true,
        },
        'rundown.instance.setInstanceStatus.ignoreFloatedInstances': {
          label: 'Ignore floated instances when changing status',
          type: 'boolean',
          default: false,
          visible: true,
          description:
            'Ignore floated instances when changing statuses of all of the ready instances',
        },
        'rundown.instance.scheduleToReadyRundown': {
          label: 'Schedule instances to the ready rundown',
          type: 'boolean',
          default: false,
          visible: true,
          description: 'Schedule the instances to the ready rundown',
        },
        'rundown.instance.createParentStory': {
          label: 'Create parent story',
          type: 'boolean',
          default: true,
          visible: true,
          description: 'Create parent story (When rundown only instance feature turned off)',
        },
        'rundown.instance.updateScriptDurationField': {
          label: 'Script Duration',
          type: 'boolean',
          default: false,
          visible: true,
          description: 'Enable updating script duration',
        },
        'rundown.pollInterval': {
          label: 'Poll interval',
          type: 'number',
          default: 10,
          description: 'Poll interval for opened rundown(in minutes)',
          visible: true,
        },

        'shortcuts.rundown.movetimeline': {
          label: 'Move timeline shortcut',
          type: 'string',
          default: 'ctrl+space',
          visible: true,
          description: 'Press desired key combination to move the timeline.',
        },
        'shortcuts.rundown.jumptimeline': {
          label: 'Jump timeline shortcut',
          type: 'string',
          default: 'ctrl+shift+space',
          visible: true,
          description: 'Press desired key combination to jump the timeline.',
        },
        'rundown.hideInOutTimingInGraphics': {
          label: 'Hide In/Out timing fields on secondary automation items',
          type: 'boolean',
          default: false,
          description: 'Hide In/Out timing fields on secondary automation items',
          visible: true,
        },
        'rundown.includeFramesInOutTimingCalculation': {
          label: 'Include frames in In/Out timing fields',
          type: 'boolean',
          default: false,
          description:
            'Include frames in In/Out timing fields calculations on secondary automation items',
          visible: true,
        },
        'rundown.videoFrameRate': {
          label: 'Video frame rate (fps)',
          type: 'number',
          default: 25,
          description: 'Video frame rate in frames per second (fps). Default 25 fps',
          visible: true,
        },
        'rundown.disableInstanceArchiving': {
          label: 'Show option to prevent an Instance from being archived',
          type: 'boolean',
          default: false,
          description: `Show "Prevent Archiving" in Instance context-menus`,
          visible: true,
        },
      },
    },
    {
      title: 'Resource booking',
      description: 'General resource booking properties',
      visible: true,
      properties: {
        'scheduler.enable': {
          label: 'Resource booking',
          type: 'boolean',
          default: false,
          description: 'Enable resource booking',
          visible: true,
        },
      },
    },
    {
      title: 'MOS',
      description: 'MOS settings properties',
      visible: true,
      properties: {
        'mos.usemoshtmlautomationpanel': {
          label: 'Enable MOS Automation HTML panel',
          type: 'boolean',
          default: false,
          description: 'Enable MOS Automation HTML panel',
          visible: true,
        },
        'mos.usemositemreplace': {
          label: 'Enable Handling of The MOS Item Replace command from the HTML panel',
          type: 'boolean',
          default: false,
          description: 'Enable mosItemReplace',
          visible: true,
        },
      },
    },
  ],
};

export default mainSettingsView;
