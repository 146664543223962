import { useCallback } from 'react';
import { atom, useAtom } from 'jotai';
import { v4 } from 'uuid';

export interface ShowToast {
  title: string | null;
  type: 'info' | 'error' | 'success';
  description?: string;
}

interface InternalToast extends ShowToast {
  id: string;
  setOpen: (val: boolean) => void;
}

const toastsAtom = atom<InternalToast[]>([]);
const useInternalToastsState = () => useAtom(toastsAtom);

/**
 * Custom hook to manage toast notifications.
 *
 * @param {boolean} autoClose - Whether the toast should auto-close. Default is false.
 * @param {number} duration - Duration in ms before auto-closing. Default is 10000 ms.
 * @returns {object} - Contains toast, errorToast functions and current toasts state.
 */
const useToast = (autoClose: boolean = false, duration: number = 10000) => {
  const [toasts, setToasts] = useInternalToastsState();

  const toast = useCallback(
    (t: ShowToast) => {
      const id = v4();
      const newToast: InternalToast = {
        ...t,
        id,
        setOpen: (val: boolean) => {
          if (!val) {
            setToasts((prevToasts) => [...prevToasts.filter((to) => to.id !== id)]);
          }
        },
      };
      setToasts((prevToasts) => [...prevToasts, newToast]);

      if (autoClose) {
        setTimeout(() => {
          newToast.setOpen(false);
        }, duration); // Auto close after specified duration
      }
    },
    [setToasts, autoClose, duration],
  );

  const errorToast = useCallback(
    (err: unknown, description?: string) => {
      // eslint-disable-next-line no-console
      console.error(JSON.stringify(err, null, 2));
      toast({
        title: 'Error',
        description: description ? description : 'Something has gone wrong',
        type: 'error',
      });
    },
    [toast],
  );

  const successToast = useCallback(
    (description: string) => {
      toast({
        title: 'Success',
        description,
        type: 'success',
      });
    },
    [toast],
  );

  return { toast, errorToast, successToast, toasts };
};

export default useToast;
